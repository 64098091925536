<template>
  <div class="wrapper">
    <div class="text-alin">
        <a class="link-a page_menu_item" href="/莲湖.apk"  download="智慧养老APP"> <img style="width:70px;height:70px" src="@/assets/images/appDownloadBtn.png"  alt="" /><span>智慧养老APP</span></a>
    </div>
    <div class="page_container">

      <div class="page_menu">
        <a @click="jump1" class="page_menu_item">
          <img src="@/assets/images/s1.png" alt="" />
          <span>智慧养老监管平台</span>
        </a>
        <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>
        <a @click="jump2" class="page_menu_item">
          <img src="@/assets/images/s2.png" alt="" />
          <span>日间照料中心管理平台</span>
        </a>
        <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>
        <a @click="jump3" class="page_menu_item">
          <img src="@/assets/images/s3.png" alt="" />
          <span>老年餐管理平台</span>
        </a>
        <!-- <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>
        <a @click="jump4" class="page_menu_item">
          <img src="@/assets/images/s4.png" alt="" />
          <span>适老化机构管理云平台</span>
        </a> -->
        <a @click="jump5" class="page_menu_item">
          <img src="@/assets/images/s5.png" alt="" />
          <span>家庭床位管理云平台</span>
        </a>
        <!-- <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>
        <a @click="jump6" class="page_menu_item">
          <img src="@/assets/images/s6.png" alt="" />
          <span>家庭巡视管理云平台</span>
        </a> -->
        <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>
        <a @click="jump7" class="page_menu_item">
          <img src="@/assets/images/s7.png" alt="" />
          <span>呼叫中心管理云平台</span>
        </a>
        <!-- <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>
        <a @click="jump8" class="page_menu_item">
          <img src="@/assets/images/s8.png" alt="" />
          <span>养老机构业务管理云平台</span>
        </a> -->
        <!-- <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>
        <a @click="jump9" class="page_menu_item">
          <img src="@/assets/images/s9.png" alt="" />
          <span>评估机构业务管理云平台</span>
        </a> -->
        <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>


        <a @click="jump15" class="page_menu_item">
          <img src="@/assets/images/icon13.png" alt="" />
          <span>养老顾问服务报告</span>
        </a>
                <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>

        <a @click="jump16" class="page_menu_item">
          <img src="@/assets/images/icon11.png" alt="" />
          <span>人员评估</span>
        </a>

        <a @click="jump17" class="page_menu_item">
          <img src="@/assets/images/icon12.png" alt="" />
          <span>适老化改造</span>
        </a>
          <a @click="jump11" class="page_menu_item">
          <img src="@/assets/images/s11.png" alt="" />
          <span>大数据管理云平台</span>
        </a>
        <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div>
         <!-- <a @click="jump12" class="page_menu_item">
          <img src="@/assets/images/s8.png" alt="" />
          <span>志愿者管理系统</span>
        </a>
        <div class="page_menu_item_line">
          <img src="@/assets/images/line.png" alt="" />
        </div> -->
        <a @click="jump10" class="page_menu_item">
          <img src="@/assets/images/s10.png" alt="" />
          <span>系统管理</span>
        </a>

      </div>
    </div>
    <div class="footer">
      <p>&copy; All Rights Reserved. 版权所有</p>
      <p>备案信息：<a style="color: #515a6e"  target="_blank" href="https://beian.miit.gov.cn/">陕ICP备2022011559号</a> </p>
      <p>技术支持：赵先生 &nbsp; 联系电话：15389282601</p>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
export default {
  name: 'MyHome',
  data() {
    return {
      subSysList: [],
      link: {
        SystemManagement: false,
      },
      menuList: {},
    }
  },
  created() {
    // this.initInformation()
    this.initMenu()
  },
  methods: {
    ...mapMutations(['goToPage']),
    ...mapActions(['getSubSysListServe']),
    ...mapActions(['getSystemList']),
    initInformation() {
      let server = 'getSubSysListServe'
      this[server]().then((res) => {
        if (res.code == 200) {
          this.subSysList = res.data.subSysList
          this.link.SystemManagement = this.subSysList.includes('系统管理')
        }
      })
    },
    initMenu() {
      let server = 'getSystemList'
      this[server]().then((res) => {
        if (res.code == 200) {
          this.menuList = res.data
          console.log(this.menuList)
        }
      })
    },
    jumpToPage(hasPermision, pageInfo) {
      console.log(hasPermision)
      if (hasPermision) {
        this.goToPage(pageInfo)
      } else {
        this.$XModal.message({
          message: '没有权限访问，请联系系统管理员',
          status: 'error',
        })
      }
    },

    jumpToPage2(hasPermision, pageInfo) {
      console.log(hasPermision)
      if (hasPermision) {
        window.location.href = 'index2.html'
      } else {
        this.$XModal.message({
          message: '没有权限访问，请联系系统管理员',
          status: 'error',
        })
      }
    },

    jump1() {
      sessionStorage.menu = '0'
      this.jumpToPage(this.menuList.pesnsion, {
        name: 'PersonManage',
      })
    },
    jump2() {
      sessionStorage.menu = '1'
      this.jumpToPage(this.menuList.dayCareCenter, {
        name: 'SumCenter',
      })
    },
    jump3() {
      sessionStorage.menu = '2'
      this.jumpToPage(this.menuList.mealManage, {
        // name: 'AgedEatdataCenter',
        name: 'operateDailys',


      })
    },
    jump4() {
      sessionStorage.menu = '3'
      this.jumpToPage(this.menuList.suitAgea, {
        name: 'ChgCompany',
      })
    },
    jump5() {
     // window.open("https://www.yongaimujia.com/index")
      // sessionStorage.menu = '4'
      // this.jumpToPage(this.menuList.familyBed, {
      //   name: 'BedCompany',
      // })
      sessionStorage.menu = '15'
      this.jumpToPage(true, {
        name: 'dataFlash',
      })
    },
    jump6() {
      sessionStorage.menu = '5'
      this.jumpToPage(this.menuList.patrolVisit, {
        name: 'ServeOrgs',
      })
    },
    jump7() {
      sessionStorage.menu = '6'
      this.jumpToPage(this.menuList.webCall, {
        name: 'CallCenter',
      })
    },
    jump8() {
      sessionStorage.menu = '7'
      this.jumpToPage(this.menuList.organization, {
        name: 'datacenter',
      })
    },
    jump9() {
      sessionStorage.menu = '8'
      this.jumpToPage(this.menuList.evalAgency, {
        name: 'EvaluationAgency',
      })
    },
    jump10() {
      sessionStorage.menu = '9'
      this.jumpToPage(this.menuList.sysManage, {
        name: 'DictType',
      })
    },
    jump11() {
      sessionStorage.menu = '10'
      this.jumpToPage2(this.menuList.dataDash, {
        name: 'DictType',
      })
    },
    //志愿者管理系统
    jump12(){
      sessionStorage.menu = '10'
      this.jumpToPage(true, {
        name: 'volunteerInfo',
      })
    },
    //养老顾问服务报告
    jump15(){
      sessionStorage.menu = '15'
      this.jumpToPage(true, {
        name: 'personRecord',
      })
    },
    //人员评估
    jump16(){
      sessionStorage.menu = '15'
      this.jumpToPage(true, {
        name: 'personAssess',
      })
    },
    //适老化改造
    jump17(){
      sessionStorage.menu = '15'
      this.jumpToPage(true, {
        name: 'oldChange',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  color: #fff;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
footer span {
  margin: 0 10px;
}
.wrapper {
  width: 100%;
  height: 100%;
  background: url('../../../assets/images/bg.jpg') no-repeat center;
  background-attachment: fixed;
}
.page_container {
  width: 100%;
  height: 100%;
}
.page_menu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1160px;

  display: flex;
  flex-wrap: wrap;
  /*justify-content: center;*/
}
.page_menu_item {
  width: 240px;
  height: 90px;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.page_menu_item_line {
  width: 48px;
  height: 90px;
  display: flex;
  align-items: flex-end;
}
.page_menu_item_line img {
  width: 48px;
  height: 48px;
}
.page_menu_item img {
  width: 82px;
  height: 84px;
}
.page_menu_item span {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.footer {
  position: fixed;
  bottom: 20px;
  width: 100%;
  p {
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
}
.text-alin{
  position: absolute;
  // width: 100%;
  // height: 90%;
  top:50%;
  left:90%;

}
  // .text-alin:hover{
  //   left: 96%;
  // }

</style>
